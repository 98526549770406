import { FormsModule } from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './theme/shared/shared.module';
import {AppComponent} from './app.component';
import {AdminComponent} from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {NavigationComponent} from './theme/layout/admin/navigation/navigation.component';
import {NavLogoComponent} from './theme/layout/admin/navigation/nav-logo/nav-logo.component';
import {NavContentComponent} from './theme/layout/admin/navigation/nav-content/nav-content.component';
import {NavigationItem} from './theme/layout/admin/navigation/navigation';
import {NavGroupComponent} from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import {NavCollapseComponent} from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import {NavItemComponent} from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import {NavBarComponent} from './theme/layout/admin/nav-bar/nav-bar.component';
import {ToggleFullScreenDirective} from './theme/shared/full-screen/toggle-full-screen';
import {NgbButtonsModule, NgbDropdownModule, NgbModule, NgbTabsetModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {NavLeftComponent} from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import {NavSearchComponent} from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import {NavRightComponent} from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {AuthInterceptor} from './auth/auth.interceptor';
import {AuthService} from './auth/auth.service';
import {AuthGuard} from './auth/auth-guard.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ToastyModule} from 'ng2-toasty';
import { SortablejsModule } from 'ngx-sortablejs';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavLogoComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    ToggleFullScreenDirective,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    ToastyModule.forRoot(),
    SortablejsModule.forRoot({ animation: 150 })
  ],
  exports: [ToastyModule],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    AuthService,
    AuthGuard,
    NavigationItem],
  bootstrap: [AppComponent]
})
export class AppModule {
}

<ng-container *ngIf="!item.hidden">
  <li [ngClass]="item.classes" *ngIf="item.url && !item.external" [routerLinkActive]="['active']">
    <a class="nav-link" [target]="item.target ? '_blank' : '_self'" [routerLink]="this.item.url.search(':') < 0 ? [item.url] : ''" (click)="closeOtherMenu($event); executeAction(this.item.url)">
      <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
      <span class="pcoded-mtext" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
      <ng-template #directTitle>
        {{item.title}}
      </ng-template>
      <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
    </a>
  </li>
  <li [ngClass]="item.classes" *ngIf="item.url && item.external">
    <a [target]="item.target ? '_blank' : '_self'" [href]="item.url">
      <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
      <span class="pcoded-mtext" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
      <ng-template #directTitle>
        {{item.title}}
      </ng-template>
      <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
    </a>
  </li>
  <ng-template #itemIcon>
    <span *ngIf="item.icon && item.iconType === 'feather'" class="pcoded-micon"><i class="feather" [ngClass]="item.icon"></i></span>
    <span *ngIf="item.icon && item.iconType === 'custom'" class="pcoded-micon">
      <app-custom-icons [icon]="item.icon" [size]="'16px'" [color]="'#ffffff'"></app-custom-icons>
    </span>
  </ng-template>
  <ng-template #itemBadge>
    <span *ngIf="item.badge && themeLayout === 'vertical'" class="pcoded-badge label" [ngClass]="item.badge.type">
      {{item.badge.title}}
    </span>
    <span *ngIf="item.badge && themeLayout === 'horizontal'" class="badge label" [ngClass]="item.badge.type">
      {{item.badge.title}}
    </span>
  </ng-template>
</ng-container>

import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService} from './auth/auth.service';
import {Subscription} from 'rxjs';
import { ToastyConfig } from 'ng2-toasty';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    private authListenerSubs: Subscription;
    isLogged = false;
    position = 'bottom';

    constructor(
        private router: Router,
        private auth: AuthService,
        private toastyConfig: ToastyConfig
    ) {
        this.auth.autoAuthUser();
        this.isLogged = this.auth.getIsAuth();
        this.authListenerSubs = this.auth
            .getAuthStatusListener()
            .subscribe(isAuthenticated => {
                this.isLogged = isAuthenticated;
            });
        console.log('this.isLogged', this.isLogged);



        // TOASTY CONFIG
        this.toastyConfig.position = 'top-right';
        this.toastyConfig.timeout = 4000;
        this.toastyConfig.showClose = true;
        this.toastyConfig.theme = 'bootstrap';
    }

    ngOnInit(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

}

<div class="navbar-brand header-logo">
  <a [routerLink]="['/dashboard/default/']" class="b-brand">
    <div class="logo">
      <img src="assets/logo/icx_white.svg" class="img-fluid">
    </div>
    <span class="b-title text-left" *ngIf="userData">
      <span class="mb-0 d-block">{{userData.name + ' ' + userData.surname}}</span>
      <small class="d-block">{{instance.projectName}}</small>
    </span>
  </a>
  <a href="javascript:" class="mobile-menu" [ngClass]="{'on' : navCollapsed}" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
</div>

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Instance } from 'src/app/auth/instance.model';
import { InstancesService } from 'src/app/auth/instances.service';
import {DattaConfig} from '../../../../app-config';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavCollapsedMob = new EventEmitter();
  public dattaConfig: any;
  public navCollapsed;
  public navCollapsedMob;
  public windowWidth: number;
  public userData: any = {};
  public instance: Instance = {};

  constructor(
    private authService: AuthService,
    private instService: InstancesService
  ) {
    this.dattaConfig = DattaConfig.config;
    this.windowWidth = window.innerWidth;
    this.navCollapsed = (this.windowWidth >= 992) ? this.dattaConfig['collapse-menu'] : false;
    this.navCollapsedMob = false;
  }

  ngOnInit() {
    this.authService.getUser().subscribe((res) => {
      this.userData = res.data;
    },
    (err) => {
      console.log('Errore ', err.error.message);
    });
    this.instService.getInstance()
    .subscribe((res: {data: any}) => {
      this.instance = res.data;
    },
    (err) => {
      console.log('Errore ', err.error.message);
    });
  }

  navCollapse() {
    if (this.windowWidth >= 992) {
      this.navCollapsed = !this.navCollapsed;
      this.onNavCollapse.emit();
    }
  }

  navCollapseMob() {
    if (this.windowWidth < 992) {
      this.onNavCollapsedMob.emit();
    }
  }
}

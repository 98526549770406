import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';


const apiUrl = environment.apiUrl + '/auth';

@Injectable({providedIn: 'root'})
export class AuthService {
    private isAuthenticated = false;
    private token: string;
    private tokenTimer: any;
    private userId: string;
    private authStatusListener: Subject<any> = new Subject<any>();
    private errorListener : Subject<any> = new Subject<any>();
    userEmail;
    message;

    constructor(private http: HttpClient, private router: Router) {
    }

    getToken(): string {
        return this.token;
    }

    getIsAuth(): boolean {
        return this.isAuthenticated;
    }

    getUserId(): string {
        // console.log('user in getuserid', this.userId);
        return this.userId;
    }

    getAuthStatusListener(): any {
        return this.authStatusListener.asObservable();
    }

    getErrorListerner(): any {
        return this.errorListener.asObservable();
    }

    createUser(userData): void {
        this.http.post(apiUrl + '/signin', userData).pipe(map(res => res)).subscribe((res: any) => {
                this.userEmail = res.user;
                this.router.navigate(['/pending'], {queryParams: {email: this.userEmail}});
            },
            error => {
                this.authStatusListener.next(false);
                console.log(error);
            });
    }

    login(userData): any {
        this.http.post(apiUrl + '/login', userData).subscribe(
            (response: any) => {
                const token = response.token;
                this.token = token;
                if (token) {
                    const expiresInDuration = response.expiresIn;
                    this.setAuthTimer(expiresInDuration);
                    this.isAuthenticated = true;
                    this.userId = response.userId;
                    this.authStatusListener.next(true);
                    const now = new Date();
                    const expirationDate = new Date(
                        now.getTime() + expiresInDuration * 1000
                    );
                    console.log(expirationDate);
                    this.saveAuthData(token, expirationDate, this.userId);
                    this.router.navigate(['/']);
                }
            },
            error => {
                console.log(error);
                this.errorListener.next({message: error.error.message});
                this.authStatusListener.next(false);
            }
        );
    }

    autoAuthUser(): any {
        // console.log('autoauth');
        const authInformation = this.getAuthData();
        if (!authInformation) {
            return;
        }
        const now = new Date();
        const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
        // console.log('expiresIn', expiresIn / 1000);
        if (expiresIn > 0) {
            this.token = authInformation.token;
            this.isAuthenticated = true;
            this.userId = authInformation.userId;
            // console.log('id auto auth', this.userId);
            /*
                        this.setAuthTimer(expiresIn / 1000);*/

            this.setAuthTimer(expiresIn / 1000);
            this.authStatusListener.next(true);
        }
    }

    logout(): void {
        this.token = null;
        this.isAuthenticated = false;
        this.authStatusListener.next(false);
        this.userId = null;
        clearTimeout(this.tokenTimer);
        this.clearAuthData();
        this.router.navigate(['/auth']);
        console.log('logout');
    }

    private setAuthTimer(duration: number): void {
        console.log('Setting timer: ' + duration * 1000);
        this.tokenTimer = setTimeout(() => {
            console.log('ìlogout da timeout');
            this.logout();
        }, duration * 1000);
    }

    private saveAuthData(token: string, expirationDate: Date, userId: string): any {
        localStorage.setItem('token', token);
        localStorage.setItem('expiration', expirationDate.toISOString());
        localStorage.setItem('userId', userId);
    }

    private clearAuthData(): any {
        localStorage.removeItem('token');
        localStorage.removeItem('expiration');
        localStorage.removeItem('userId');
    }

    private getAuthData(): any {
        const token = localStorage.getItem('token');
        const expirationDate = localStorage.getItem('expiration');
        const userId = localStorage.getItem('userId');
        if (!token || !expirationDate) {
            return;
        }
        return {
            token,
            expirationDate: new Date(expirationDate),
            userId
        };
    }

    getUser(): Observable<any> {
        return this.http.get(environment.apiUrl + '/adminUser/getUser/' + this.userId);
    }
}

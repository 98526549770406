import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-custom-icons',
  templateUrl: './custom-icons.component.html',
  styleUrls: ['./custom-icons.component.scss']
})
export class CustomIconsComponent implements OnInit {

  @Input() icon;
  @Input() size;
  @Input() color;
  attr = {};

  constructor() { }

  ngOnInit(): void {
    this.attr = {
      width: this.size,
      height: this.size,
      fill: this.color
    };
  }

}

import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  iconType?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'NAVIGATION',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard',
        classes: 'nav-item',
        iconType: 'feather',
        icon: 'feather icon-home',
      },
      {
        id: 'experiences',
        title: 'Experiences',
        type: 'collapse',
        url: '/experiences',
        classes: 'nav-item',
        icon: 'experiences.svg',
        iconType: 'custom',
        children: [
          {
            id: 'experiences_all',
            title: 'All Experiences',
            type: 'item',
            url: '/experiences'
          },
          {
            id: 'experience',
            title: 'Add New Experience',
            type: 'item',
            url: '/experiences/new'
          }
        ]
      },
      {
        id: 'interactions',
        title: 'Interactions',
        type: 'collapse',
        url: '/interactions',
        classes: 'nav-item',
        icon: 'interactions.svg',
        iconType: 'custom',
        children: [
          {
            id: 'interactions_all',
            title: 'All Interactions',
            type: 'item',
            url: '/interactions'
          },
          {
            id: 'interaction',
            title: 'Add New Interaction',
            type: 'item',
            url: '/interactions/new'
          }
        ]
      },
      {
        id: 'prizes',
        title: 'Prizes',
        type: 'collapse',
        url: '/prizes',
        classes: 'nav-item',
        icon: 'prizes.svg',
        iconType: 'custom',
        children: [
          {
            id: 'prizes_all',
            title: 'All Prizes',
            type: 'item',
            url: '/prizes'
          },
          {
            id: 'prize',
            title: 'Add New Prize',
            type: 'item',
            url: '/prizes/new'
          }
        ]
      },
      {
        id: 'settings',
        title: 'Settings',
        type: 'item',
        iconType: 'feather',
        icon: 'feather icon-settings',
        url: '/settings'
      },
      {
        id: 'logout',
        title: 'Logout',
        type: 'item',
        url: ':logout',
        classes: 'nav-item',
        iconType: 'feather',
        icon: 'feather icon-log-out',
      },
      
      /* {
        id: 'help',
        title: 'Help',
        type: 'item',
        iconType: 'feather',
        icon: 'feather icon-help-circle',
        url: '/help'
      }, */
      
    ]
  }
];

@Injectable()
export class NavigationItem {
  get() {
    return NavigationItems;
  }
}
